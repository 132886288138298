import React from "react";
import Countdown from "react-countdown";

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <span className="text-lg font-bold text-gray-700">Finalizado</span>
  } else {
    // Render a countdown
    return <span className="text-red-500 text-lg">{days} días - {hours}h {minutes}m {seconds}s</span>;
  }
};

const CowntDown = ({auctionDate}) => {
  const date = new Date(auctionDate)
  return <Countdown date={date} renderer={renderer} />;
};

export default CowntDown;
